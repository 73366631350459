.landingpage .header {
    display: flex;
    justify-content: space-between;
    padding: 20px 5%;
    background-color: #ffff;
    position: fixed;
    width: 100%;
    z-index: 100;
    box-shadow: 1px 1px 12px black;
}

.landingpage .applynow {
    text-align: center;
    color: white;
    margin-top: 7%;
    background-color: #004a7c;
    padding: 20px;


}

.landingpage .applynow .text b {
    animation: blinkAnimation 1.5s infinite;
    cursor: pointer;
    /* Apply the blink animation */
    font-weight: 800;
}

@keyframes blinkAnimation {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}


.landingpage .header .logo img {
    width: 60%;
}

.landingpage {
    overflow-y: scroll;
    height: 100vh;
    overflow-x: hidden;
}

.intl-tel-input {
    width: 100%;
}

.intl-tel-input input {
    width: 100%;
    height: 40px;
    border: 1px solid rgb(159, 154, 154);
    border-radius: 5px;
}

.landingpage .contents h4 {
    margin-top: 20%;
    color: rgb(0, 0, 0);
    font-weight: 600;
    padding: 1px 10%;
    text-align: center;

}

.landingpage .contents p {
    color: rgb(9, 9, 9);
}

.landingpage .h2tag {
    background: linear-gradient(to right, #ee5227, #1900ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.h3tag {
    background: linear-gradient(to right, black, #000000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.landingpage .contents .form {
    margin: 15px 6%;
    background-color: #004a7c;
    padding: 40px;
    border-radius: 10px;
    transform: translateX(100%);
    color: white;
    animation: slideIn 0.5s ease-out forwards;
}

.landingpage .programs {
    text-align: start;
}

.landingpage .programs h4 {

    background: linear-gradient(to right, #000000, #1900ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 900;
}

h2 {
    color: #333;
}

h3 {
    color: #555;
}

ul {
    margin-bottom: 20px;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

.landingpage .body-content {
    background: #004a7c;
}

.landingpage .body-content img {
    width: 100%;
}

.landingpage .body-content p {
    font-size: 20px;
}

.landingpage h1 {
    background: linear-gradient(to right, #ee5227, #1900ff);
    text-align: center;
    -webkit-background-clip: text;
    margin-top: 10%;
    -webkit-text-fill-color: transparent;

}

.landingpage .card {
    background-color: #004a7c;
    color: white;
    cursor: pointer;

}

.landingpage .card img {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 2px solid white;
    margin-bottom: 20px;
}


@media only screen and (max-width: 600px) {
    .form {
        margin: 15px 1% !important;
    }

    .landingpage .applynow {
        text-align: center;
        color: white;
        margin-top: 1%;
        background-color: #004a7c;
        padding: 20px;

    }

    .landingpage h1 {
        font-size: 20px;
    }

    .landingpage .body-content {
        background: #004a7c !important;
    }

}

