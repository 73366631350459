@font-face {
  font-family: "General Sans";
  src: url("../public/font/GeneralSans/Fonts/OTF/GeneralSans-Regular.otf");
}

body,
html {
  font-family: "General Sans" !important;
}

html,
body,
#root,
.App {
  height: 100vh;
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
  background: #fdfdfd;
  overflow: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: block;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  /* scrollbar-width: none; Firefox */
}
